<template>
    <div>
        <div class="e-breadcrumb">广告位管理</div>
        <div class="order-table-wrapper">
            <div class="e-card">
                <div class="e-card-body" style="padding-top:35px">
                    <div class="e-handle-box" style="margin-bottom: 50px">
                        <div class="handle-box">
                            <span class="v-form-label">图片名称:</span>
                            <el-input placeholder="图片名称" size="small" style="width:265px" v-model="adName" clearable
                                      class="handle-input mr10"></el-input>
                            <span class="v-form-label">广告类型:</span>
                            <el-select class="handle-input mr10" size="small" style="width:265px" v-model="adType"
                                       filterable clearable placeholder="请选择广告类型">
                                <el-option
                                        v-for="item in adTypeList"
                                        :key="item.dictValue"
                                        :label="item.name"
                                        :value="item.dictValue"
                                ></el-option>
                            </el-select>
                            <span class="v-form-label">创建日期:</span>
                            <el-date-picker
                                    v-model="cDate"
                                    size="small"
                                    type="daterange"
                                    range-separator="至"
                                    value-format="yyyy-MM-dd"
                                    start-placeholder="开始日期"
                                    end-placeholder="结束日期">
                            </el-date-picker>
                            <div style="margin-left:  235px">
                                <el-row type="flex" justify="end">
                                    <el-button size="small" class="e-button" type="primary" @click="searchEvent">查询
                                    </el-button>
                                    <el-button size="small" class="e-button" type="success" @click="addEvent()">新增
                                    </el-button>
                                </el-row>
                            </div>
                        </div>
                    </div>
                    <br/> <br/>

                    <div class="e-table-wrapper">
                        <table cellspacing="0" cellpadding="0" border="0" width="100%" style="text-align: center">
                            <thead>
                            <tr>
                                <th width="130" class="th-l">名称</th>
                                <th class="th-l">图片</th>
                                <th width="260" class="th-l">类型</th>
                                <th width="200" class="th-l">创建时间</th>
                                <th width="120" class="th-l">操作</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(row,index) in list" :key="row.id">
                                <td class="td-l"><span>{{row.adName}}</span></td>
                                <td class="td-l" style="padding: 10px 12px">
                                    <img :src="row.adImgUrl" style="width: 200px;height: 140px;"/>
                                </td>
                                <td class="td-l">
                                    <p v-for="item in adTypeList" v-if="item.dictValue == row.adType">{{item.name}}</p>
                                </td>
                                <td class="td-l">
                                    <p style="margin: 5px 0;width: 300px">
                                        {{row.createTime}}
                                    </p>
                                </td>
                                <td class="td-l" style="width: 160px">
                                    <el-button type="text" icon="el-icon-edit" @click="editEvent(index)">编辑
                                    </el-button>
                                    <el-button type="text" icon="el-icon-delete" class="red"
                                               @click="removeEvent(index)">删除
                                    </el-button>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <div class="pagination">
                            <el-pagination
                                    background
                                    @current-change="handleCurrentChange"
                                    :pager-count="7"
                                    layout="total, prev, pager, next,jumper"
                                    :total="pagination.count"
                                    :page-count="pagination.pages"
                                    :current-page="pagination.pageNumber"
                            ></el-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <Insert v-if="addVisible" :addVisible="addVisible" @closeInsert="closeInsert" @addSuccess="addBbiAdSuccess"
                :seaportItem="seaportItem"/>
        <Edit v-if="editVisible" :editVisible="editVisible" @closeEdit="closeEdit" @editSuccess="editBbiAdSuccess"
              :editItem="editItem" :seaportItem="seaportItem"/>

        <!-- 删除提示框 -->
        <el-dialog title="提示" :visible.sync="delVisible" width="300px" center>
            <div class="del-dialog-cnt">删除不可恢复，是否确定删除？</div>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="delVisible = false">取 消</el-button>
                <el-button size="small" type="primary" @click="delBbiAd">确 定</el-button>
            </span>
        </el-dialog>


    </div>
</template>

<script>
    import Insert from './insert'
    import Edit from './edit'

    export default {
        data() {
            return {
                id: '',
                adName: '',
                adImgUrl: '',
                adType: '',
                adSort: '',
                adUrl: '',
                createTime: '',
                creatorId: '',
                modifyTime: '',
                modifierId: '',
                isDel: '',
                list: [],
                pagination: {
                    count: 0,
                    pageNumber: 0,
                    pageSize: 0,
                    pages: 1
                },
                cDate: [],
                useList: [],
                adTypeList: [],
                addVisible: false,
                editVisible: false,
                delVisible: false,
                enableVisible: false,
                prohibitVisible: false,
                selItem: {},
                editItem: {},
                seaportItem: [],
                options: [{
                    value: '1',
                    label: '是'
                }, {
                    value: '0',
                    label: '否'
                }]
            }
        },
        async created() {
            this.searchEvent();
            this.getAllUser();
            this.adTypeChange();
        },
        methods: {
            async adTypeChange() {
                let dataSet = await this.$get('/admin/getDictDetailList/AD_TYPE/' + Math.random())
                this.adTypeList = dataSet;

            },
            handleCurrentChange(page) {
                this.requestSearch(page)
            },
            searchEvent() {
                this.requestSearch()
            },
            async requestSearch(page) {
                let currentPage = page || 1;
                let staDate = '';
                let endDate = '';
                if (this.cDate && this.cDate.length > 0) {
                    staDate = this.cDate[0]
                    endDate = this.cDate[1]
                }
                let data = {
                    adName: this.adName,
                    adType: this.adType,
                    staDate: staDate,
                    endDate: endDate,
                    size: this.size == '' ? '10' : this.size,
                    currentPage
                }
                let dataSet = await this.$get("/admin/getBbiAdForPage", data)
                let res = dataSet.dataSet;
                let list = res.list
                this.list = list;
                this.pagination = {
                    count: res.count,
                    pageNumber: res.pageNumber,
                    pageSize: res.pageSize,
                    pages: res.pages
                }
                console.log(this.pagination)
            },
            async getAllUser() {
                let dataSet = await this.$get("/admin/getAllUser")
                let list = dataSet.useList;
                this.useList = list;
            },
            statusEvent(index) {
                this.selItem = this.list[index]
                if (!this.selItem.isForbidden) {
                    this.prohibitVisible = true
                } else {
                    this.enableVisible = true
                }
            },
            addEvent() {
                this.addVisible = true;
            },

            editEvent(index) {
                this.editItem = this.list[index]
                this.editVisible = true;
            },

            async removeEvent(index) {
                this.selItem = this.list[index]
                this.delVisible = true;
            },

            //删除BbiAd
            async delBbiAd() {
                let data = {
                    id: this.selItem.id
                };
                let res = await this.$post("/admin/remove_bbiAd", data)
                if (res.code == 200) {
                    this.searchEvent();
                    this.$message({
                        showClose: true,
                        message: res.message,
                        type: 'success'
                    });
                    this.delVisible = false;
                } else {
                    this.$message({
                        showClose: true,
                        message: res.message,
                        type: 'error'
                    });
                }
            },

            closeInsert() {
                this.addVisible = false;
            },

            closeEdit() {
                this.editVisible = false;
            },

            addBbiAdSuccess() {
                this.closeInsert();
                this.searchEvent();
            },

            editBbiAdSuccess() {
                this.closeEdit();
                this.searchEvent();
            }
        },
        components: {
            Insert,
            Edit
        }
    }

</script>

<style scoped>
    .th-l {
        text-align: center;
        padding-right: 10px;
    }

    .td-l {
        padding: 10px 12px;
        display: table-cell;
        text-align: center;
        vertical-align: middle;
    }
</style>
